import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { get, sortBy } from 'lodash';
import { SEO } from '../components/common/seo';
import { getSchema } from '../common/schema';
import { parseStories, parseUpdate } from '../common/content';
import { StorySection } from '../components/story/section';
import { useLocale } from '../context/locale';

export const query = graphql`
  query UpdateQuery($id: String!) {
    settings: site {
      siteMetadata {
        siteUrl
      }
    }
    site: datoCmsSite {
      ...SiteInformation
    }
    page: datoCmsUpdate(
      id: { eq: $id }
    ) {
      model {
        apiKey
      }
      meta {
        ...PageMeta
      }
      seo {
        ...PageSEO
      }
      locale
      dateFormatted: date(formatString: "DD MMMM YYYY")
      date
      stories {
        ...StoryCard
      }
    }
    versions: allDatoCmsUpdate(
      filter: {date: {ne: null}}
    ){
      nodes {
        locale
        model {
          apiKey
        }
      }
    }
  }
`;

const UpdatePage = ({ data, pageContext }: any) => {
  const settings = get(data, 'settings.siteMetadata');
  const page = get(data, 'page');
  const site = get(data, 'site');
  
  const versions = get(data, 'versions.nodes');

  if (!page || !settings || !site) return null;

  const schema = getSchema({ settings, site, page, pageContext });

  const locale = useLocale();

  const stories = parseStories(page.stories);

  const update = parseUpdate(page,locale);

  return (
    <Fragment>
      <SEO 
        tags={page.seoMetaTags}
        favicon={site.faviconMetaTags}
        siteURL={settings.siteUrl}
        schema={schema}
        isArticle={false}
        page={page}
        versions={versions}
      />
      <StorySection {...update} />
    </Fragment>
  );
};

export default UpdatePage;